a {
  text-decoration: none;
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}

.fs-14 {
  font-size: 14px;
}

.tab-item {
  a {
    color: $dark;
    font-weight: 400;
  }

  .icon {
    color: $secondary;
  }

  a:hover {
    border-bottom: 2px solid $secondary-25;
    color: $dark;
  }

  .active {
    border-bottom: 2px solid #fd8c73;
    font-weight: 600;
    color: $dark;

    .icon {
      color: $dark;
    }
  }

  .active:hover {
    border-bottom: 2px solid #fd8c73;
  }
}

.dashed {
  border-width: 1px;
  border-style: dashed;
}

#photo-upload {
  display: none;
}

.profile-photo-input {
  width: 120px;
  height: 120px;
  background-color: #eeeeee;
  border-radius: 50%;
  cursor: pointer;
}

.patient-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
